import {useNavigate} from 'react-router';
import {useMutation} from '@apollo/client';

import {
    createGetMoneyKzShowcase,
    createGetMoneyKzShowcaseVariables
} from '../../../../graphql/__generated__/createGetMoneyKzShowcase';
import {MUTATION_CREATE_GETMONEY_KZ_SHOWCASE} from '../../../../graphql/getmoney-kz';

import {GetMoneyKzShowcaseForm,IOffer} from './GetMoneyKzShowcaseForm';

export const GetMoneyKzShowcaseNew = () => {

    const initValues: IOffer = {
        logo: {
            id: '',
            url: '',
        },
        headline: '',
        percent: '',
        term: '',
        age: '',
        URL: '',
    }

    const [create] = useMutation<createGetMoneyKzShowcase, createGetMoneyKzShowcaseVariables>(MUTATION_CREATE_GETMONEY_KZ_SHOWCASE)
    const navigate = useNavigate()

    const onFinish = async (data: IOffer) => {
        await create({
            variables: {
                data:
                    {
                        ...data,
                        logo: data.logo.id,
                        position: 1,
                    },
            },
            onCompleted: () => {
                navigate(-1)
            },
        })
    }

    return (
    <>
        <h2>Новый оффер</h2>
        <GetMoneyKzShowcaseForm values={initValues} action={'Создать'} onFinish={onFinish}/>
    </>
    )

}