export const ROOT = '';
export const HOME = '/';
export const LOGIN = '/login';
export const FORGET = '/forget';

export const REFUND = '/refund';
export const SFTP = '/sftp';
export const LOSS = '/loss';
export const ACTIVATE = '/activate';
export const CONSULTATION = '/consultation';
export const PARTNER = '/partner';

export const GETMONEY_KZ = '/getmoney-kz';
export const GETMONEY_KZ_REGS = '/getmoney-kz/regs';
export const GETMONEY_KZ_REG = '/getmoney-kz/user/:id';
export const GETMONEY_KZ_LEADS = '/getmoney-kz/leads';
export const GETMONEY_KZ_ORDERS = '/getmoney-kz/orders';
export const GETMONEY_KZ_SHOWCASES = '/getmoney-kz/showcases';
export const GETMONEY_KZ_PREVIEW = '/getmoney-kz/preview';
export const GETMONEY_KZ_SHOWCASE = '/getmoney-kz/showcase/:id';
export const toGetMoneyKZLead = (id:string) => `/getmoney-kz/lead?id=${id}`;
export const toGetMoneyKzShowcaseEdit = (id:string) => `/getmoney-kz/showcase/${id}`
export const toGetMoneyKzUser = (id:string) => `/getmoney-kz/user/${id}`


export const ONLYCASH_KZ = '/onlycash-kz';
export const ONLYCASH_KZ_REG = '/onlycash-kz/reg';
export const ONLYCASH_KZ_LEAD = '/onlycash-kz/lead';
export const OnlyCashKZLeadID = (id:string) => `/onlycash-kz/lead?id=${id}`;
export const ONLYCASH_RU = '/onlycash-ru';
export const ONLYCASH_RU_LEADS = '/onlycash-ru/leads';
export const ONLYCASH_RU_SHOWCASES = '/onlycash-ru/showcases';
export const ONLYCASH_RU_SHOWCASE = '/onlycash-ru/showcase/:id';
export const toOnlyCashRuShowcaseEdit = (id:string) => `/onlycash-ru/showcase/${id}`

export const DELIVERY_FILES = '/delivery/files';
export const DELIVERY_RECORDS = '/delivery/records/:id';
export const DeliveryRecordsID = (id:string) => `/delivery/records/${id}`;

export const SMS_INFO = '/sms';
export const SMS_FILES = '/sms/files';
export const SMS_INFOS = '/sms/infos';
export const SmsInfosID = (id:string) => `/sms/infos/?file=${id}`;
// export const SmsInfosID = (_:string) => '/sms/infos';


