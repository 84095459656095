import type {DefaultOptions} from '@apollo/client';
import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {message} from 'antd';
import {createUploadLink} from 'apollo-upload-client';
import {getApiBase} from 'env';

import {IRootStoreModel} from '../store/RootStore';

const API_BASE = getApiBase();

const ApiClientFactory = (store: IRootStoreModel) => {
    const errorLink = onError(({graphQLErrors, networkError, operation}) => {
        const {response} = operation.getContext();

        if (graphQLErrors) {
            graphQLErrors.forEach(async (error) => {
                    console.log(
                        `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
                    )
                    await message.error(`${error.message}`);
                }
            );
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            store.auth.clearState();
        } else if (response && response.status === 401) {
            // Если токен сдох, то разлогиниваем пользователя
            store.auth.clearState();
        }
    });

    const authLink = setContext((_, {headers}) => {
        if (!store.auth.token) {
            return headers;
        }
        const token = store.auth.token;

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    const uploadLink = createUploadLink({
        uri: `${API_BASE}/graphql`,
    });

    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    }

    return new ApolloClient({
        link: ApolloLink.from([errorLink, authLink, uploadLink]),
        cache: new InMemoryCache(),
        defaultOptions: defaultOptions,
    });
};

export default ApiClientFactory;
