import { useParams } from 'react-router-dom';

import {GetMoneyKzShowcaseEdit} from './GetMoneyKzShowcaseEdit';
import {GetMoneyKzShowcaseNew} from './GetMoneyKzShowcaseNew';

export const GetMoneyKzShowcase = () => {
    const {id} = useParams()
    return (
        <div className={'form'}>
            {id === 'new' ?
                <GetMoneyKzShowcaseNew/>
                :
                <GetMoneyKzShowcaseEdit id={id!}/>
            }
        </div>
    )
}