import {CSVLink} from 'react-csv';
import {Link, useSearchParams} from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import { DisplayDate } from 'helpers/DisplayDate';
import _ from 'lodash';

import {ENUM_GETMONEYKZLEAD_STATUS} from '../../../__generated__/global-types';
import {getMoneyKzLeads, getMoneyKzLeadsVariables} from '../../../graphql/__generated__/getMoneyKzLeads';
import {QUERY_GETMONEY_KZ_LEADS} from '../../../graphql/getmoney-kz';
import {toGetMoneyKzUser} from '../../../routes/routes';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './GetMoneyKZLeads.module.scss'

export function displayGetMoneyKzLeadStatus(status: ENUM_GETMONEYKZLEAD_STATUS) {

    if (!status) return ''

    let name = ''
    switch (status) {
        case ENUM_GETMONEYKZLEAD_STATUS.pending:
            name = '🕓 В обработке';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.pending_sent:
            name = '🕓📤 В обработке';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.pending_error:
            name = '🕓❌ Ошибка отправки';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.rejected:
            name = '⬅️ Отклонено';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.rejected_sent:
            name = '⬅️📤 Отклонено';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.rejected_error:
            name = '⬅️❌ Ошибка отправки';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.approved:
            name = '✅ Принято';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.approved_sent:
            name = '✅📤 Принято';
            break;
        case ENUM_GETMONEYKZLEAD_STATUS.approved_error:
            name = '✅❌ Ошибка отправки';
            break;
    }

    return name
}

export const GetMoneyKZLeads = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<getMoneyKzLeads, getMoneyKzLeadsVariables>(QUERY_GETMONEY_KZ_LEADS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            }
        }
    })


    // function displayReg(lead: onlyCashKzs_onlyCashKzs_data_attributes_lead|null|undefined) {
    //     return lead ? <Link to={'/'}>{lead.data?.id}</Link> : ''
    // }

    const dataSource = _.map(query.data?.getMoneyKzLeads?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: DisplayDate(item.attributes?.createdAt),
        registerAt: DisplayDate(item.attributes?.registerAt),
        loginAt: DisplayDate(item.attributes?.loginAt),
        orderAt: DisplayDate(item.attributes?.orderAt),
        paidAt: DisplayDate(item.attributes?.paidAt),
        refundAt: DisplayDate(item.attributes?.refundAt),
        status: displayGetMoneyKzLeadStatus(item.attributes?.status!),
        FIO: item.attributes?.user?.data?.attributes?.FIO,
    }))

    const columns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'UID',
            dataIndex: 'session',
            key: 'session',
        },
        {
            title: 'Провайдер',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
            render: (value:string, record:any) => <Link to={toGetMoneyKzUser(record.user?.data?.id)}>{value}</Link>
        },
        {
            title: 'Посещение',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Регистрация',
            dataIndex: 'registerAt',
            key: 'registerAt',
        },
        {
            title: 'Логин',
            dataIndex: 'loginAt',
            key: 'loginAt',
        },
        {
            title: 'Заказ',
            dataIndex: 'orderAt',
            key: 'orderAt',
        },
        {
            title: 'Оплата',
            dataIndex: 'paidAt',
            key: 'paidAt',
        },
        {
            title: 'Возврат',
            dataIndex: 'refundAt',
            key: 'refundAt',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'GetMoneyKZ-Leads.csv'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>
        </>

    )

}