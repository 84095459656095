import {useState} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button, Form, Input, message, Upload} from 'antd'
import type {RcFile} from 'antd/es/upload/interface';

import {upload, uploadVariables} from '../../../../graphql/__generated__/upload';
import {MUTATION_UPLOAD} from '../../../../graphql/delivery';
import {imgUrl} from '../../../../helpers/imgUrl';
export interface ILogo {
    id: string;
    url: string;
}

export interface IOffer {
    logo: ILogo;
    headline: string;
    percent: string;
    term: string;
    age: string;
    URL: string;
}

export interface IFormOffer {
    values: IOffer
    action: string
    onFinish: (data: IOffer) => void
    loading?: boolean
}

export const GetMoneyKzShowcaseForm = ({values, action, onFinish, loading}:IFormOffer) => {

    const [logo, setLogo] = useState<ILogo>(values.logo)
    const [upload] = useMutation<upload, uploadVariables>(MUTATION_UPLOAD)
    const [form] = Form.useForm()

    const beforeUpload = (file: RcFile) => {
        const isExcel = file.type === 'image/jpg' || file.type === 'image/png';
        if (!isExcel) {
            message.error('Только файлы в формате JPG и PNG');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('Только файлы до 10Мб');
        }
        return isExcel && isLt10M;
    };

    const beforeFinish = (data:IOffer) => {
        onFinish({
            ...data,
            logo: logo,
        })
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} onFinish={beforeFinish} initialValues={values} disabled={loading}>
            <Form.Item name={'headline'} label={'Заголовок'} rules={[
                {required: true, message: 'Заполните поле'}
            ]}>
                <Input placeholder={'от 1 000 до 30 000 ₽'}/>
            </Form.Item>
            <Form.Item name={'percent'} label={'Ставка'} rules={[
                {required: true, message: 'Заполните поле'}
            ]}>
                <Input placeholder={'0,8% в день'}/>
            </Form.Item>

            <Form.Item name={'logo'} hidden={true} rules={[
                {required: true, message: 'Загрузите картинку'}
            ]}/>

            <Form.Item label={'Логотип'}>
                {logo.url && <img src={logo.url} alt={'Logo'}/>}
                <Upload.Dragger
                    multiple={false}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    customRequest={async ({file}) => {
                        await upload({
                            variables: {file: file},
                            onCompleted: (data) => {
                                setLogo({id: data.upload.data?.id!, url: imgUrl(data.upload.data?.attributes?.url!)})
                                form.setFieldValue('logo', data.upload.data?.id!)
                            },
                        });
                    }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Для загрузки кликните или бросьте сюда файл</p>
                    <p className="ant-upload-hint">
                        Поддерживается 1 файл изображения в формате jpg, png.
                    </p>
                </Upload.Dragger>
            </Form.Item>
            <Form.Item name={'term'} label={'Срок'} rules={[
                {required: true, message: 'Заполните поле'}
            ]}>
                <Input placeholder={'от 7 до 30 дней'}/>
            </Form.Item>
            <Form.Item name={'age'} label={'Возраст'} rules={[
                {required: true, message: 'Заполните поле'}
            ]}>
                <Input placeholder={'от 18 лет\t'}/>
            </Form.Item>
            <Form.Item name={'URL'} label={'URL'} rules={[
                {required: true, message: 'Заполните поле'},
                {type: 'url', message: 'Не в формте URL'},
            ]}>
                <Input placeholder={'https://example.com/123345'}/>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>{action}</Button>
            </Form.Item>
        </Form>
    )
}