import {gql} from '@apollo/client';
export const QUERY_REFUND = gql`
    query refundRequests($filters: RefundRequestFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        refundRequests(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    type
                    orderStatus
                    orderValue
                    FIO
                    Email
                    Phone
                    requestData
                    requestPdfFileUrl
                    status
                    template
                    Certificate_Number
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;